import React from 'react'
import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram, FaGithub } from 'react-icons/fa';
import './followAt.scss';

const followAt = () => {
  return (
    <div className="follow-at">
      <a className="fa-follow" href='https://linkedin.com/in/marcdomain' target='_blank' rel="noopener noreferrer">
        <FaLinkedin style={{color: `#2867B2`}} />
      </a>
      <a className="fa-follow" href='https://github.com/marcdomain' target='_blank' rel="noopener noreferrer">
        <FaGithub className="fa-github" />
      </a>
      <a className="fa-follow" href='https://twitter.com/marcdomain' target='_blank' rel="noopener noreferrer">
        <FaTwitter style={{color: `#1DA1F2`}} />
      </a>
      <a className="fa-follow" href='https://facebook.com/marcdomains' target='_blank' rel="noopener noreferrer">
        <FaFacebook style={{color: `#3b5998`}} />
      </a>
      <a className="fa-follow" href='https://instagram.com/marcdomain' target='_blank' rel="noopener noreferrer">
        <FaInstagram style={{color: `#F56040`}} />
      </a>
    </div>
  )
}

export default followAt;
