/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import CustomiseLinks from 'customise-links';
import ReadingProgress from './progressBar';
import FollowAt from './followAt';
import Header from "./header"
import "./profile.scss"
import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const target = React.createRef();

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <ReadingProgress target={target} />
      <div ref={target}>
        <div
          style={{
            maxWidth: 1200,
            margin: `0 auto`,
            padding: `0 1.0875rem 1.45rem`
          }}
        >
          <main>{children}</main>
          <FollowAt />
          <footer style={{textAlign: `center`, marginTop: `30px`}}>
          <a href="https://www.linkedin.com/in/marcdomain/" target="_blank" rel="noopener noreferrer">@marcdomain</a>
          {` `}
          © {new Date().getFullYear()}
          </footer>
        </div>
      </div>
      {CustomiseLinks()}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
