import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import ProfileImage from './profileImage';

const Header = ({ siteTitle }) => {
  const [backgroundColor] = useState('#000C1D')
  const [color] = useState('#ffffff')
  const [titleText] = useState('white')
  const [githubColor] = useState('white')

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    document.body.style.color = color;
    document.querySelector('.fa-github').style.color = githubColor;

    const selectTitleTexts = document.querySelectorAll('h4')
    const titleTextsLength = selectTitleTexts.length - 1
    for (let i = 0; i <= titleTextsLength; i++) {
      selectTitleTexts[i].style.color = titleText;
    }
  }, [backgroundColor, color, titleText, githubColor])

  return (
    <header
      style={{
        background: `#000`,
        marginBottom: `1.45rem`,
        position: `fixed`,
        width: `100vw`,
        zIndex: `10000`
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1200,
          padding: `1rem 1.0875rem`,
          display: `flex`,
          justifyContent: `space-between`
        }}
      >
        <div style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
              fontFamily: `Signika, sans-serif`
            }}
          >
            <div className='hover-link'>
              {siteTitle}
            </div>
          </Link>
        </div>
        <div className="logo">
         <Link to="/">
          <ProfileImage />
         </Link>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
